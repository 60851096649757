import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Layer from '@arcgis/core/layers/Layer';

import { createExtentFromPoint } from 'utils/esriUtils';

export const PARCEL_OUTLINES_LAYER_ID = 'parcel-outlines-map-layer';
export const PARCEL_OUTLINES_LAYER_URL =
    'https://tiles.arcgis.com/tiles/KzeiCaQsMoeCfoCq/arcgis/rest/services/Regrid_Nationwide_Parcel_Boundaries_v1/MapServer';

export const PARCEL_QUERY_FEATURES_LAYER_URL =
    'https://fs.regrid.com/otfjlL4RwGHNLiXOueo02yh31UwdJlMcPC8kYQjYcW2GKsxrOR2tA6Ia37GOndlH/rest/services/premium/FeatureServer/0';

export const queryParcelOutlinesLayer = async (mapPoint: __esri.Point) => {
    const featureLayer = (await Layer.fromArcGISServerUrl({
        url: PARCEL_QUERY_FEATURES_LAYER_URL,
    })) as FeatureLayer;
    const extent = createExtentFromPoint(mapPoint);
    const result = await featureLayer.queryFeatures({
        where: `1=1`,
        geometry: extent,
        returnGeometry: true,
        outFields: ['*'],
    });
    return result.features;
};
