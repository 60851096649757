import { createSlice, freeze, PayloadAction } from '@reduxjs/toolkit';

import { OSM_POLYGON_HEX_COLOR } from 'helpers/osmBuildingLayerHelper';
import { RootState } from 'store';
import { LayerExcludedBuildings } from '../types/BuildingsExcludeSettings';
import { VisibilityRange } from './mapRangeSlice';
import { clearPresentation, fetchPresentation } from './presentationSlice';

interface FeatureState {
    visible: boolean;
}

export type EdgeOptions = 'solid' | 'sketch';

export interface EdgeStyle {
    visible: boolean;
    color: string;
    size: number;
    type: string;
}

export interface BuildingStyleOptions {
    color: string;
    edge: EdgeStyle;
}

export interface BuildingState extends FeatureState {
    color: string;
    edge: EdgeStyle;
    opacity: number;
    excludedOsmKeys: number[];
    excludedDevPipelineKeys: number[];
    edit?: boolean;
    showMissingPins: boolean;
}

export interface LegendState extends FeatureState {
    openKeys?: string[];
}

export interface Label3DState extends FeatureState, VisibilityRange {}

export const defaultLabel3DRange = {
    visible: false,
    minScale: 0,
    maxScale: 0,
};

export const initialEdgeState = {
    visible: true,
    size: 0.25,
    type: 'solid',
    color: '#000000',
};

export interface FeatureStates {
    buildings: BuildingState;
    labels3d: Label3DState;
    terrain: FeatureState;
    trees: FeatureState;
    legends: LegendState;
    basemap?: string;
    parcelOutlines: FeatureState;
}

interface FeaturesSliceState {
    states: FeatureStates;
}

const initialBuildingState = {
    visible: true,
    color: OSM_POLYGON_HEX_COLOR,
    edge: initialEdgeState,
    opacity: 100,
    excludedOsmKeys: [],
    excludedDevPipelineKeys: [],
    showMissingPins: false,
};

export const initialState: FeaturesSliceState = freeze(
    {
        states: {
            buildings: initialBuildingState,
            labels3d: defaultLabel3DRange,
            terrain: {
                visible: true,
            },
            trees: {
                visible: false,
            },
            legends: {
                visible: false,
                placement: 'BOTTOM-RIGHT',
            },
            parcelOutlines: {
                visible: false,
            },
        },
    },
    true
);

export const featuresSlice = createSlice({
    name: 'features',
    initialState,
    reducers: {
        setFeatureStates: (state, action: PayloadAction<FeatureStates>) => {
            Object.assign(state.states, action.payload);
        },
        setShowMissingPins: (state, action: PayloadAction<boolean>) => {
            state.states.buildings.showMissingPins = action.payload;
        },
        setBuildingsColor: (state, action: PayloadAction<string>) => {
            state.states.buildings.color = action.payload;
        },
        setBuildingsEdge: (state, action: PayloadAction<EdgeStyle>) => {
            state.states.buildings.edge = action.payload;
        },
        setBuildingsOpacity: (state, action: PayloadAction<number>) => {
            state.states.buildings.opacity = action.payload;
        },
        setBuildingsVisible: (state, action: PayloadAction<boolean>) => {
            state.states.buildings.visible = action.payload;
        },
        setLabels3dVisible: (state, { payload: visible }: PayloadAction<boolean>) => {
            state.states.labels3d.visible = visible;
        },
        setLabels3dRange: (state, action: PayloadAction<VisibilityRange>) => {
            state.states.labels3d.minScale = action.payload?.minScale;
            state.states.labels3d.maxScale = action.payload?.maxScale;
        },
        setExcludeBuildings: (state, action: PayloadAction<LayerExcludedBuildings>) => {
            const { buildingIds, layer } = action.payload;
            if (layer == 'OSM') {
                state.states.buildings.excludedOsmKeys = buildingIds;
            } else if (layer == 'DevPipeline') {
                state.states.buildings.excludedDevPipelineKeys = buildingIds;
            }
        },
        addExcludeBuilding(state, action: PayloadAction<LayerExcludedBuildings>) {
            const { layer, buildingIds } = action.payload;
            const { excludedOsmKeys, excludedDevPipelineKeys } = state.states.buildings;

            if (layer === 'OSM') {
                state.states.buildings.excludedOsmKeys = [...excludedOsmKeys, ...buildingIds];
            } else if (layer === 'DevPipeline') {
                state.states.buildings.excludedDevPipelineKeys = [
                    ...excludedDevPipelineKeys,
                    ...buildingIds,
                ];
            }
        },
        removeExcludeBuilding(state, action: PayloadAction<LayerExcludedBuildings>) {
            const { layer, buildingIds } = action.payload;
            const { excludedOsmKeys, excludedDevPipelineKeys } = state.states.buildings;

            if (layer === 'OSM') {
                state.states.buildings.excludedOsmKeys = excludedOsmKeys.filter(
                    (buildingId) => !buildingIds.includes(buildingId)
                );
            } else if (layer == 'DevPipeline') {
                state.states.buildings.excludedDevPipelineKeys = excludedDevPipelineKeys.filter(
                    (buildingId) => !buildingIds.includes(buildingId)
                );
            }
        },
        setTerrainVisible: (state, action: PayloadAction<boolean>) => {
            state.states.terrain.visible = action.payload;
        },
        setTreesVisible: (state, action: PayloadAction<boolean>) => {
            state.states.trees.visible = action.payload;
        },
        setLegendVisible: (state, action: PayloadAction<boolean>) => {
            state.states.legends.visible = action.payload;
        },
        setParcelOutlinesVisible: (state, action: PayloadAction<boolean>) => {
            state.states.parcelOutlines.visible = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);

        builder
            .addCase(fetchPresentation.pending, () => initialState)
            .addCase(fetchPresentation.fulfilled, (state, { payload: { presentation } }) => {
                const mapFeatures = presentation.mapFeatures;
                if (!mapFeatures) return;
                state.states.buildings = {
                    ...mapFeatures.buildings,
                    excludedOsmKeys: mapFeatures?.buildings?.excludedOsmKeys ?? [],
                    excludedDevPipelineKeys: mapFeatures?.buildings?.excludedDevPipelineKeys ?? [],
                    color: mapFeatures?.buildings?.color ?? OSM_POLYGON_HEX_COLOR,
                    edge: mapFeatures?.buildings?.edge,
                };
                state.states.legends = mapFeatures?.legends;
                state.states.trees = mapFeatures?.trees;
                state.states.terrain = mapFeatures.terrain;
                state.states.basemap = mapFeatures.basemap;
                state.states.labels3d = mapFeatures?.labels3d ?? initialState.states.labels3d;
                state.states.parcelOutlines =
                    mapFeatures?.parcelOutlines ?? initialState.states.parcelOutlines;
            });
    },
});

export const {
    setShowMissingPins,
    setBuildingsVisible,
    setBuildingsColor,
    setBuildingsEdge,
    setBuildingsOpacity,
    setLabels3dVisible,
    setTerrainVisible,
    setTreesVisible,
    setLegendVisible,
    setFeatureStates,
    setExcludeBuildings,
    addExcludeBuilding,
    removeExcludeBuilding,
    setLabels3dRange,
    setParcelOutlinesVisible,
} = featuresSlice.actions;

export const selectExcludedOsmKeys = (state: RootState): number[] => {
    return state.features.states.buildings.excludedOsmKeys ?? [];
};

export const selectFeatures = (state: RootState) => state.features.states;

export const selectShowMissingPins = (state: RootState): boolean => {
    return state.features.states.buildings.showMissingPins;
};

export const selectExcludedDevPipelineKeys = (state: RootState): number[] => {
    return state.features.states.buildings.excludedDevPipelineKeys ?? [];
};

export const selectIsMapLegendVisible = (state: RootState): boolean =>
    !!state.features.states.legends?.visible;

export const selectLabels3dSettings = (state: RootState): Label3DState =>
    state.features.states.labels3d;

export const selectLegendState = (state: RootState): LegendState => state.features.states.legends;

export const selectParcelOutlinesState = (state: RootState): FeatureState =>
    state.features.states.parcelOutlines;

export default featuresSlice.reducer;
