import { Spin } from '@jll/react-ui-components';

export interface FullScreenSpinnerProps {
    isAbsolutePositioned?: boolean;
}
export function FullScreenSpinner(props: FullScreenSpinnerProps): JSX.Element {
    return (
        <div
            style={{
                position: props.isAbsolutePositioned ? 'absolute' : undefined,
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Spin size='large' />
        </div>
    );
}
