import { ReactNode } from 'react';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import ChatMessage from 'components/Chat/ChatMessage';
import { SystemType } from 'components/Chat/systems/systemTypes';
import { RootState } from 'store';
import config from 'utils/config';
import { nanoid } from 'utils/idUtils';
import { ActionBarKeys, selectActiveActionBar } from './actionBarSlice';
import { selectActiveRightPanel } from './rightPanelSelectors';
import { RightPanelKeys } from './rightPanelSlice';

interface AssistantPanelState {
    showAssistant: boolean;
    subscriptionKey: string;
    apiUrl: string;
    model: string;
    messages: ChatMessage[];
    loading: boolean;
    thinkingMessage: string;
    systemType?: SystemType;
}

export enum OpenAiModels {
    GPT_35_TURBO = 'GPT_35_TURBO',
    GPT_35_TURBO_0125 = 'GPT_35_TURBO_0125',
    GPT_4 = 'GPT_4',
    GPT_4_32K = 'GPT_4_32K',
    GPT_4_TURBO = 'GPT_4_TURBO',
    GPT_4_O = 'GPT_4_O',
    CLAUDE_3_SONNET_35 = 'CLAUDE_3_SONNET_35',
    BAIDU_ERNIE_40 = 'BAIDU_ERNIE_40',
    BAIDU_ERNIE_3_5_128K = 'BAIDU_ERNIE_3_5_128K',
}

const initialState: AssistantPanelState = {
    showAssistant: false,
    subscriptionKey: config.blackbirdAssistantApiSubscriptionKey,
    apiUrl: config.blackbirdAssistantApiUrl,
    model: OpenAiModels.GPT_4_O,
    messages: [],
    loading: false,
    thinkingMessage: 'Thinking...',
};

const assistantSlice = createSlice({
    name: 'assistant',
    initialState,
    reducers: {
        createNewMessage: {
            reducer: (state, action: { payload: ChatMessage }) => {
                state.messages.push(action.payload);
            },
            prepare: (payload: {
                message: string | ReactNode;
                user: 'Assistant' | 'User';
                messageType?: SystemType;
            }) => {
                const chatMessage: ChatMessage = {
                    message: payload.message,
                    key: nanoid(),
                    type: payload.messageType,
                    timestamp: Date.now(),
                    user: payload.user,
                };
                return { payload: chatMessage };
            },
        },
        setThinkingMessage: (state, action: { payload: string }) => {
            state.thinkingMessage = action.payload || 'Thinking...';
        },

        addMessage: (state, action: { payload: ChatMessage }) => {
            state.messages.push(action.payload);
        },
        appendMessage: (state, action: { payload: { key: string; content: string } }) => {
            const message = state.messages.find((message) => message.key === action.payload.key);
            if (message) {
                message.message += action.payload.content;
            }
        },
        clearMessages: (state) => {
            state.messages = [];
            state.loading = false;
            state.systemType = undefined;
        },
        setLoading: (state, action: { payload: boolean }) => {
            state.loading = action.payload;
        },

        setSystemType: (state, action: { payload: SystemType | undefined }) => {
            state.systemType = action.payload;
        },
    },
});

export const {
    setThinkingMessage,
    addMessage,
    clearMessages,
    setLoading,
    appendMessage,
    createNewMessage,
    setSystemType,
} = assistantSlice.actions;

export const selectSubscriptionKey = (state: RootState) => state.assistant.subscriptionKey;
export const selectApiUrl = (state: RootState) => state.assistant.apiUrl;
export const selectModel = (state: RootState) => state.assistant.model;
export const selectMessages = (state: RootState) => state.assistant.messages;
export const selectLoading = (state: RootState) => state.assistant.loading;
export const selectThinkingMessage = (state: RootState) => state.assistant.thinkingMessage;
export const selectSystemType = (state: RootState) => state.assistant.systemType;
export const selectAssistantVisible = createSelector(
    [selectActiveRightPanel, selectActiveActionBar],
    (activeRightPanel, activeActionBar) => {
        const isAssistantRightPanelVisible = activeRightPanel?.name === RightPanelKeys.Assistant;
        const isAssistantActionBarVisible = activeActionBar?.name === ActionBarKeys.Assistant;

        return isAssistantRightPanelVisible || isAssistantActionBarVisible;
    }
);

export default assistantSlice.reducer;
