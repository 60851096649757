import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { selectPresentationModeEnabled } from './applicationModeSlice';
import { closeMarketSurveyPanel, setSelectedT2HBuilding } from './tableHighlightSetSlice';

export enum LeftPanelKeys {
    Presentation = 'presentation',
    SinglePropertyView = 'single_property_view',
    MarketSurvey = 'market_survey',
    CsvLayerData = 'csv_layer_data',
    TableHighlightSet = 'table_highlight_set',
}

export const LeftPanelLabels = {
    [LeftPanelKeys.Presentation]: 'Presentation',
    [LeftPanelKeys.SinglePropertyView]: 'Single Property View',
    [LeftPanelKeys.MarketSurvey]: 'Market Survey',
    [LeftPanelKeys.CsvLayerData]: 'CSV Layer Data',
    [LeftPanelKeys.TableHighlightSet]: 'Table Highlight Set',
};

export interface LeftPanelItem {
    name: LeftPanelKeys;
    hiddenInPresentationMode?: boolean;
}

const leftPanels: {
    [key in LeftPanelKeys]: LeftPanelItem;
} = {
    [LeftPanelKeys.Presentation]: {
        name: LeftPanelKeys.Presentation,
        hiddenInPresentationMode: true,
    },
    [LeftPanelKeys.SinglePropertyView]: {
        name: LeftPanelKeys.SinglePropertyView,
    },
    [LeftPanelKeys.MarketSurvey]: {
        name: LeftPanelKeys.MarketSurvey,
    },
    [LeftPanelKeys.CsvLayerData]: {
        name: LeftPanelKeys.CsvLayerData,
        hiddenInPresentationMode: false,
    },
    [LeftPanelKeys.TableHighlightSet]: {
        name: LeftPanelKeys.TableHighlightSet,
        hiddenInPresentationMode: false,
    },
};

interface LeftPanelState {
    activePanel: LeftPanelItem | null;
}

export const initialState: LeftPanelState = {
    activePanel: leftPanels[LeftPanelKeys.Presentation],
};

export const leftPanelSlice = createSlice({
    name: 'leftPanel',
    initialState,
    reducers: {
        setActiveLeftPanel: (state, action: PayloadAction<LeftPanelKeys>) => {
            const panel = leftPanels[action.payload];
            state.activePanel = panel;
        },

        toggleActiveLeftPanel: (state, action: PayloadAction<LeftPanelKeys>) => {
            const panel = leftPanels[action.payload];
            state.activePanel = state.activePanel?.name === panel.name ? null : panel;
        },

        clearLeftPanel: (state) => {
            state.activePanel = null;
        },

        resetLeftPanel: (state) => {
            state.activePanel = leftPanels[LeftPanelKeys.Presentation];
        },
    },

    extraReducers(builder) {
        builder
            .addCase(setSelectedT2HBuilding, (state, action) => {
                if (action.payload.csvLayerMetadata?.marketSurvey) {
                    state.activePanel = leftPanels[LeftPanelKeys.MarketSurvey];
                } else {
                    state.activePanel = leftPanels[LeftPanelKeys.TableHighlightSet];
                }
            })
            .addCase(closeMarketSurveyPanel, (state) => {
                state.activePanel = leftPanels[LeftPanelKeys.Presentation];
            });
    },
});

export const { setActiveLeftPanel, clearLeftPanel, toggleActiveLeftPanel, resetLeftPanel } =
    leftPanelSlice.actions;

const selectLeftPanel = (state: RootState): LeftPanelState => state.leftPanel;

export const selectIsSinglePropertyViewVisible = createSelector(selectLeftPanel, (leftPanel) => {
    return leftPanel.activePanel?.name === LeftPanelKeys.SinglePropertyView;
});

export const selectActiveLeftPanel = createSelector(
    [selectPresentationModeEnabled, (state: RootState) => state.leftPanel.activePanel],
    (isPresentationModeEnabled, activePanel) => {
        if (!activePanel) {
            return null;
        }

        if (isPresentationModeEnabled && activePanel.hiddenInPresentationMode) {
            return null;
        }

        return activePanel;
    }
);

export default leftPanelSlice.reducer;
